/* HEEBO FONT IMPORT */
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* CORMORAT FONT IMPORT */
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Heebo:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Quentin";
  src: url("../public/fonts/Quentin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.quentin {
  font-family: "Quentin", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  color: #6b5c45 !important;
}

.cormorant {
  font-family: "Cormorant", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  word-wrap: break-word;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.cormorant-title {
  font-family: "Cormorant", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

/* FONTS AREA FINISHED */

body {
  background-color: #171718;
}
.subtitle-color {
  color: #988779;
}
a,
p,
i {
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  /* color: #0000008a; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.item {
  width: 20%;
  overflow: visible;
  stroke: #000;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.dItems:hover span {
  height: 100%;
}

/* PARALLAX-SECTION STARTED */
.parallax {
  height: 500px;
  background-attachment: fixed; /* Parallax için bu önemli */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* İçerik için */
.content {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 2rem;
}
/* PARALLAX-SECTION FINISHED */

/* BUBBLE-SECTION STARTED */
.hoverText {
  transition: 0.35s font-weight, 0.35s color;
}

.hoverText:hover {
  font-weight: 900;
  color: rgb(238, 242, 255);
}

/* To the right */
.hoverText:hover + .hoverText {
  font-weight: 500;
  color: rgb(199, 210, 254);
}

.hoverText:hover + .hoverText + .hoverText {
  font-weight: 300;
}

/* To the left */
.hoverText:has(+ .hoverText:hover) {
  font-weight: 500;
  color: rgb(199, 210, 254);
}

.hoverText:has(+ .hoverText + .hoverText:hover) {
  font-weight: 300;
}
/* BUBBLE-SECTION FINISHED */

/* BUTTON-LAYOUT-STARTED */
.button-border {
  border: 1px solid white !important;
}
/* BUTTON-LAYOUT-FINISHED */

/* LAST-ADDED-BLOGS-SECTION-ARROW-STARTED */
.qodef-button.qodef-layout--outlined {
  color: #241c10;
  background-color: transparent;
  border: 1px solid rgba(36, 28, 16, 0.4);
}
.qodef-button.qodef-layout--outlined .qodef-m-svg-icon line {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
  stroke-dasharray: 33;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.24, 0, 0.16, 1.01);
}
.qodef-button.qodef-layout--outlined .qodef-m-svg-icon line:nth-child(2) {
  stroke-dasharray: 7;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.6s cubic-bezier(0.24, 0, 0.16, 1.01);
}
.qodef-button.qodef-layout--outlined .qodef-m-svg-icon line:nth-child(3) {
  stroke-dasharray: 7;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.6s cubic-bezier(0.24, 0, 0.16, 1.01) 0.14s;
}
.qodef-button.qodef-layout--outlined .qodef-m-svg-icon {
  transform: translateY(-8px) translateX(10px);
  margin-right: 12px;
}
.qodef-button.qodef-layout--outlined:hover .qodef-m-svg-icon line {
  stroke-dashoffset: -66;
}
.qodef-button.qodef-layout--outlined:hover .qodef-m-svg-icon line:nth-child(2) {
  stroke-dashoffset: 14;
  transition-delay: 0.14s;
}
.qodef-button.qodef-layout--outlined:hover .qodef-m-svg-icon line:nth-child(3) {
  stroke-dashoffset: -14;
}
.grid-card-item:hover .qodef-m-svg-icon line {
  stroke-dashoffset: -66;
}
.grid-card-item:hover .qodef-m-svg-icon line:nth-child(2) {
  stroke-dashoffset: 14;
  transition-delay: 0.14s;
}
.grid-card-item:hover .qodef-m-svg-icon line:nth-child(3) {
  stroke-dashoffset: -14;
}
/* LAST-ADDED-BLOGS-SECTION-ARROW-FINISHED */

/* BLOG-SECTION-STARTED */
.grid-card-item .grid-card-desc {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Maksimum 5 satır */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.latest-blog-item h1 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Maksimum 5 satır */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid-card-item img:hover {
  transform: scale(1.1) translateX(10px);
}
/* BLOG-SECTION-FINISHED */

/* FLIP-CARD-STARTED */
.card {
  height: 500px;
  perspective: 1000px; /* 3D efekti için perspektif */
  cursor: pointer;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.4s;
}

.card:hover .card-inner {
  transform: rotateX(180deg); /* Kartı döndürür */
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Kart döndüğünde diğer yüzü gizler */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.card-front {
  background-color: #333; /* Ön yüz rengi */
}

.card-back {
  background-color: #666; /* Arka yüz rengi */
  transform: rotateX(180deg); /* Arka yüzü ters çevirir */
}

.card-back img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* FLIP-CARD-FINISHED */

/* BEST-PRODUCTS-STARTED */
.product-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Maksimum 3 satır */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* BEST-PRODUCTS-FINISHED */

/* ROCKET-WRAPPER-STARTED */
.rocket {
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}
.r-img-1 {
  background: url(../public/images/1.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}
.r-img-3 {
  background: url(../public/images/3.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}
.r-img-4 {
  background: url(../public/images/4.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}
.r-img-5 {
  background: url(../public/images/5.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}
.r-img-6 {
  background: url(../public/images/6.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}
.r-img-7 {
  background: url(../public/images/7.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}
.r-img-8 {
  background: url(../public/images/8.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}

.rocket:active {
  cursor: -webkit-grabbing;
}
/* ROCKET-WRAPPER-FINISHED */

.container {
  cursor: url("https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png")
      39 39,
    auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.deck > div {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 250px;
  height: 85vh;
  max-height: 485px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.arrow {
  font-size: 2rem;
  animation: moveUpDown 3s ease-in-out infinite;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.blinking-text {
  animation: blink 4s infinite; /* 1 saniye süren ve tekrarlayan bir animasyon */
}

@keyframes blink {
  0%, 100% {
    opacity: 1; /* Başlangıç ve bitişte tamamen görünür */
  }
  50% {
    opacity: 0; /* Ortada tamamen kaybolur */
  }
}
